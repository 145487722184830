<script>
import isEqual from 'lodash-es/isEqual'
import { ref, nextTick } from 'vue'
import emitter from '/~/core/emitter'
import { createDate, formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { usePurchases } from '/~/composables/purchases'

const CINEMA_POSTER_HORIZONTAL =
  'https://i.ewalletcdn.com/ce821a14-70d4-43b3-94e3-48d27c9945f4/'

export default {
  name: 'drawer-epurchases-tile',
  components: {
    BaseButton,
    BaseImage,
  },
  inheritAttrs: false,
  props: {
    card: {
      type: Object,
      required: true,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    extended: {
      type: Boolean,
      default: false,
    },
    detailsView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { restoreGiftCard, selectedPurchaseCard } = usePurchases()

    const restoring = ref(false)

    return {
      restoring,
      formatDate,
      formatDollar,
      restoreGiftCard,
      selectedPurchaseCard,
    }
  },
  computed: {
    uuid() {
      return this.card?.uuid
    },
    type() {
      return this.card?.type
    },
    retailerName() {
      return this.card?.retailerName ?? ''
    },
    isCinema() {
      return this.type === 'cinema'
    },
    isDining() {
      return this.type === 'dining'
    },
    metaType() {
      return this.card?.metaType
    },
    imageSrc() {
      let imageSrc

      if (this.isCinema) {
        imageSrc =
          this.metaType?.movieImage ||
          this.metaType?.cinemaImage ||
          CINEMA_POSTER_HORIZONTAL
      }

      const logoUrl = this.card?.retailerImage

      return imageSrc || this.cdn(logoUrl).preview('600x600').url()
    },
    title() {
      let title

      if (this.isCinema) {
        title = [this.cinemaName, this.card?.productName]
          .filter(Boolean)
          .join(' ')
      } else if (this.isDining) {
        title = this.metaType?.restaurantName
      }

      return title || this.retailerName
    },
    cinemaName() {
      return this.metaType?.cinemaName
    },
    details() {
      return this.card?.details
    },
    url() {
      return this.details?.url
    },
    cardRoute() {
      return this.$route.meta.itemRoute
        ? `${this.$route.meta.itemRoute}/${this.uuid}`
        : {
            hash: `#profile-e-purchase/${this.uuid}`,
          }
    },
    disabled() {
      return this.card.pending || this.archived
    },
    clickable() {
      return !this.disabled && !this.detailsView
    },
    balance() {
      if (!this.card.pending) {
        return this.card?.balance ?? 0
      } else {
        return this.card?.value ?? 0
      }
    },
  },
  created() {
    /**
     * In order to trigger epurchase from order success view, there is no way other than
     * comparing few attributes of two object and assume they are identical.
     * Also, giving max 180 seconds as time difference between order success on user side and order
     * fullfilment
     */
    emitter.on('show-menu-e-purchases-item', this.showPurchasesItem)
  },
  beforeDestroy() {
    emitter.off('show-menu-e-purchases-item', this.showPurchasesItem)
  },
  methods: {
    showPurchasesItem(item) {
      const itemCreatedAt = item?.createdAt?.date
      const cardPurchasedAt = this.card?.purchasedAt
      const timeDiff = createDate(cardPurchasedAt).diff(itemCreatedAt, 'second')
      const selectedItem = {
        name: item?.retailerName,
        value: item?.value,
      }
      const card = {
        name: this.card?.retailerName,
        value: this.card?.value,
      }
      const hash = 'profile-e-purchases'

      if (isEqual(selectedItem, card) && timeDiff < 180 && !this.card.pending) {
        nextTick(() => {
          this.showCard()
        })
      } else if (this.$route.hash !== `#${hash}`) {
        this.$router.push({ hash })
        emitter.off('show-menu-e-purchases-item', this.showPurhcasesItem)
      }
    },
    onClick() {
      if (this.clickable) {
        // In order the slider does not 'jump' on click
        this.showCard()
      }
    },
    showCard() {
      nextTick(() => {
        this.selectedPurchaseCard = this.card
        if (this.card.viewed) {
          nextTick(() => this.$router.push(this.cardRoute))
        } else {
          this.$emit('showUnlockModal')
        }
        emitter.off('show-menu-e-purchases-item')
      })
    },
    async restoreCard() {
      this.restoring = true
      await this.restoreGiftCard(this.uuid)
      this.restoring = false

      emitter.emit('menu:epurchases:set-tab', 'current')
    },
  },
}
</script>

<template>
  <div
    class="group relative flex flex-col no-underline"
    :class="{
      'cursor-pointer': clickable,
    }"
  >
    <div
      class="overflow-hidden rounded-lg shadow-xs transition duration-100 group-hover:shadow-lg"
    >
      <base-image
        :src="imageSrc"
        :alt="card.retailerName"
        :style="{
          filter: disabled ? 'grayscale(1)' : undefined,
        }"
        :active="false"
        class="rounded-lg transition duration-100 group-hover:shadow-lg"
        @click="onClick"
      >
        <div
          v-if="card.pending"
          class="absolute inset-0 flex h-full w-full cursor-default items-center justify-center bg-overlay p-2.5 text-center opacity-0 transition duration-100 hover:opacity-100"
          @click.stop
        >
          <span class="p-[5px] text-sm text-white">
            Pending Delivery
            <br />
            May take up to 72 Hours to Process.
          </span>
        </div>
      </base-image>
    </div>

    <div
      v-if="!card.viewed && !card.pending"
      class="absolute right-0 top-0 -mr-[5px] -mt-[5px] rounded-xl bg-red-700 px-1.5 py-0.5 text-center text-xs font-bold uppercase text-white shadow"
    >
      new
    </div>
    <div
      class="leading-6"
      :class="[
        'relative self-center rounded-md text-center font-bold text-white',
        disabled ? 'bg-neutral-400' : 'bg-primary',
        detailsView
          ? ' -mt-6 px-6 text-xl leading-10'
          : '-mt-[15px] px-2.5 text-sm leading-6',
      ]"
    >
      {{ formatDollar(balance) }}
    </div>
    <div v-if="extended" class="mt-0.5 text-center">
      <div class="font-bold">
        {{ title }}
      </div>
      <div class="text-sm font-bold text-eonx-neutral-400">
        {{ `Purchased ${formatDate('daymonthyear', card.purchasedAt)}` }}
      </div>
    </div>
    <div v-if="archived" class="mt-2.5">
      <base-button
        :disabled="restoring"
        :loading="restoring"
        full-width
        size="sm"
        @click="restoreCard"
      >
        Restore
      </base-button>
    </div>
  </div>
</template>
